import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "store/store"
import { AsyncThunkDataType } from "store/common"
import { Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.CloseAccount.PathParameters
type RequestBody = Paths.CloseAccount.RequestBody
type ProfilesResponse = Paths.CloseAccount.Responses.$204

export const accountClose: AsyncThunkActionType<
  ProfilesResponse,
  AsyncThunkDataType<PathParameters, RequestBody, "params">
> = createAsyncThunk("accountClose", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI
  try {
    const response = await api2.closeAccount(data?.params, data?.data, data?.config)
    return response.data as ProfilesResponse
  } catch (err: any) {
    return rejectWithValue(err.response.data)
  }
})

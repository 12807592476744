import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "store/store"
import { AsyncThunkDataType } from "store/common"
import { Components, Paths } from "api/kyc/generated/client2"

type PathParameters = {
    id: string
}
type ProfilesResponse = Components.Schemas.ClientSummary[]

export const clientGet: AsyncThunkActionType<
  ProfilesResponse,
  AsyncThunkDataType<PathParameters>
> = createAsyncThunk("clientGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI
  try {
    const response = await api2.getClients(data?.params, null, data?.config)
    return response.data as ProfilesResponse
  } catch (err: any) {
    return rejectWithValue(err.response.data)
  }
})

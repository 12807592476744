import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "../../../api/kyc-backend/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Paths } from "api/kyc/generated/client2"
import { DeletedResponse } from "../slices/signedDocsSlice"

type PathParameters = Paths.DeleteSignedKyc.PathParameters

type apResp = {
  id: string,
  value: DeletedResponse
}

export const signedDocDelete: AsyncThunkActionType<
  apResp,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk(
  "signedDocDelete",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.deleteSignedKyc(params, data, config);
      return {id: params?.documentId, value: response.data} as apResp
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

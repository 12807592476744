import { createAsyncThunk } from "@reduxjs/toolkit";
import { api2 } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "api/kyc/generated/client2";

type CreateDocRequest = Paths.UploadSignedKyc.RequestBody
type resp = Components.Schemas.WithApprovalInfo
type PathParameters = Paths.UploadSignedKyc.PathParameters

export const signedDocCreate: AsyncThunkActionType<
  resp,
  AsyncThunkDataType<PathParameters, CreateDocRequest, "params">
> = createAsyncThunk(
  "signedDocCreate",
    async ({ params, data, config }, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await api2.uploadSignedKyc(params, data);
            return response.data as resp;
        } catch (err: any) {
            return rejectWithValue({
                error: true,
                status: err?.response.status, 
                data: err?.response.data,
                ...config
            });
        }
    }
);
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { api2 } from "api/kyc/api";
import { Paths } from "api/kyc/generated/client2"

type ReqBody = Paths.CreateRiskProfile.RequestBody
type Response = any


export const riskProfileCreate: AsyncThunkActionType<
    Response,
    AsyncThunkDataType<null, ReqBody, "params">
> = createAsyncThunk(
  "riskProfileCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.createRiskProfile(params, data, config);
      return response.data as Response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

import { FC, useEffect, useState } from 'react'
import { Space, Table, Tag,Popconfirm, message } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { moment } from '../../../kyc-front/ui-elements/FormDate'
import { useActionStatus } from '../../../../hooks/useActionStatus'
import { dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import TableElement from '../../../kyc-front/ui-elements/TableElement'
import { ButtonUpper } from '../../../kyc-front/ui-elements/Buttons'
import { themeColor } from '../../../../styles/themeStyles'
import { NavItem } from 'modules/kyc-front/kyc-layout/KycLayout'
import { riskProfilesGet } from 'store/kyc-backend/asyncThunks/riskProfilesGet'
import { riskProfileDeleteClear, RiskProfileType } from 'store/kyc-backend/slices/riskSlice'
import { riskProfileDelete } from 'store/kyc-backend/asyncThunks/riskProfileDelete'
import { findDefaultProfile } from './RiskProfileContainer'

interface CProps {
    handleHistoryShow: any
}


const RiskProfileHistoryTable: FC<CProps> = ({handleHistoryShow}) => {
    const envs = useSelector((state) => state.general.envs);
    const { riskProfiles, riskProfileDeleted } = useSelector((state) => state.admin2.riskProfiles);
    const [deleting, setDeleting] = useState(false);
    const [deletingId, setDeletingId] = useState('')
    const [deleteSuccess, setDeleteSuccess] = useState(false)
    const navigate = useNavigate();
    const pathParams = useParams();
    const [popupVisible, setPopupVisible] = useState('');
    const profilesGettingStatus = useActionStatus(riskProfilesGet)
     useEffect(() => {
        if(deleting) {
            if(riskProfileDeleted) {
                message.success('This element was successfully deleted')
                dispatch(riskProfileDeleteClear({}))
                dispatch(riskProfilesGet({ params: { clientId: envs.profileId} }))
                setDeleting(false)
                setDeleteSuccess(true)
                handleHistoryShow({})
            }
        }
    }, [riskProfileDeleted]);
    useEffect(() => {
        if(profilesGettingStatus.isFullfilled && deleteSuccess) {
            if(deletingId === pathParams.riskProfileId) {
                const defaultProfile = findDefaultProfile(riskProfiles)
                if(defaultProfile) {
                    navigate(envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/risk-profile/' + defaultProfile.id)
                } else {
                    handleHistoryShow({})
                    navigate(envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/risk-profile')
                }
            }
            setDeletingId('')
            setDeleteSuccess(false)
            
        }
    }, [profilesGettingStatus]);
	return (
		<div>
                <br />
                {riskProfiles.length ? (
                    <TableElement
                        sortersListProps={[]}
                        sortersState={{}}
                    >
                        {({onTableChange}) => {
                            const columns = (showHistory: any) => ([
                              {
                                title: 'Created at',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                render: (created: string) => <span>{moment(created).format('YYYY-MM-DD HH:mm:ss')}</span>,
                              },
                              {
                                title: 'Status',
                                dataIndex: 'status',
                                key: 'status',
                                render: (status: any) => <Tag color={status === 'APPROVED' ? themeColor.green : status === 'DRAFT' ? themeColor.blue : themeColor.greenGray}>{status}</Tag>
                              },
                              {
                                title: 'Score',
                                dataIndex: 'score',
                                key: 'score',
                                render: (score: any) => <Tag color={score < 3 ? themeColor.green : themeColor.orangeWarning}>{score}</Tag>
                              },
                              {
                                title: 'Actions',
                                key: 'action',
                                render: (el: any) => (
                                    <Space size="middle">
                                        <ButtonUpper className='btn-sm' btnType='primary' onClick={() => showHistory(el)} disabled={el.current ? true : false}>{el.status === 'DRAFT' ? el.current ? 'Editing' : 'Edit' : el.current ? 'Previewing' : 'Preview'}</ButtonUpper>
                                        <Popconfirm
                                            title="Are You sure you want to delete this Risk Profile?"
                                            visible={popupVisible === el.id}
                                            onVisibleChange={() => handleConfirmVisibleChange(el.id)}
                                            onConfirm={() => deleteSnapshot(el)}
                                            onCancel={cancelConfirm}
                                            okText="Delete"
                                            cancelText="Cancel"
                                            icon={<QuestionCircleOutlined />}
                                        >
                                            <ButtonUpper className='btn-sm' btnType='primary' ghost disabled={el.status === 'APPROVED'}>Delete</ButtonUpper>
                                        </Popconfirm>

                                    </Space>
                                ),
                              },
                            ]);
                            const handleConfirmVisibleChange = (id: any) => {
                                setPopupVisible(id);
                            }
                            const cancelConfirm = () => {
                                setPopupVisible('');
                            }
                            const showHistory = (el: any) => {
                                handleHistoryShow(riskProfiles.find((snap: any) => snap.id === el.id));
                                navigate(envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/risk-profile/' + el.id);
                                
                            }
                            const deleteSnapshot = (el: any) => {
                                setPopupVisible('');
                                dispatch(riskProfileDelete({params: {id: el.id}}))
                                setDeleting(true);
                                setDeletingId(el.id)
                            }
                            return (
                                <Table
                                    onChange={onTableChange} 
                                    columns={columns(showHistory)}
                                    dataSource={[...riskProfiles].sort((a: RiskProfileType, b: RiskProfileType) => {
                                        const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0
                                        const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0
                                        return dateB - dateA
                                    }).map((profile: RiskProfileType) => {
                                        return {
                                            createdAt: profile.createdAt, 
                                            id: profile.id, 
                                            isApproved: profile.requiredApprovals,
                                            current: profile.id === pathParams.riskProfileId,
                                            status: profile.status,
                                            score: profile.totalScore
                                        }
                                    })}
                                />
                            )
                        }}
                    </TableElement>
                ) : (<div></div>)}
        </div>
	)
};

export default RiskProfileHistoryTable;
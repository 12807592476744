import { Col, Divider, message, Row } from 'antd'
import Text from 'antd/es/typography/Text'
import { FC, useEffect, useState } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import { setFormUpdated } from '../../../store/kyc-front/slices/navSlice'
import { dispatch } from '../../../store/store'
import { uppercaseFirstLetter } from '../../../utilities/nameParsers'
import KycLayout, { NavItem } from '../../kyc-front/kyc-layout/KycLayout'
import { ButtonUpper } from '../../kyc-front/ui-elements/Buttons'
import { SuccessInfo } from '../../kyc-front/ui-elements/SuccessInfo'
import { activateProductClear } from 'store/kyc-backend/slices/productsSlice'
import { activateProduct } from 'store/kyc-backend/asyncThunks/activateProduct'
import { useActionStatus } from 'hooks/useActionStatus'
import { signedDocsGet } from 'store/kyc-backend/asyncThunks/signedDocsGet'
import SignedKYCUpload from '../document-uploads/SignedKYCUpload'
import { clientBlockClear, clientDeleteClear, ClientSummary, clientUnblockClear } from 'store/kyc-backend/slices/clientsSlice'
import { signedDocApprove } from 'store/kyc-backend/asyncThunks/signedDocApprove'
import { clientDelete } from 'store/kyc-backend/asyncThunks/clientDelete'
import DeleteClientModal from '../admin-actions/DeleteClient'
import BlockClientModal from '../admin-actions/BlockClient'
import { useNavigate } from 'react-router-dom'
import { clientBlock } from 'store/kyc-backend/asyncThunks/clientBlock'
import UnblockClientModal from '../admin-actions/UnblockClient'
import { clientUnblock } from 'store/kyc-backend/asyncThunks/clientUnblock'
import { themeColor } from 'styles/themeStyles'
import styled from 'styled-components'
import StickyNav from 'modules/kyc-front/ui-elements/StickyNav'
import { accountClose } from 'store/kyc-backend/asyncThunks/accountClose'
import CloseAccountModal from '../admin-actions/CloseAccount'
import { accountCloseClear } from 'store/kyc-backend/slices/accountSlice'
import { clientGet } from 'store/kyc-backend/asyncThunks/clientGet'

const ButtonUpperSuccess = styled(ButtonUpper)`
	&:not([DISABLED]){
		border-color: ${themeColor.green};
		color: ${themeColor.green};
		&.ant-btn&:not(.ant-btn-primary) {
			&:hover, &:focus, &:active {
				border-color: ${themeColor.green};
				color: ${themeColor.green};
			}
		}
	}
`

const BusinessRelation: FC = () => {
	const envs = useSelector((state) => state.general.envs)
	const { adminSuffix } = envs
	const products = useSelector((state) => state.admin2.products)
	const navEl = useSelector((state) => state.general.nav)
	const navigate = useNavigate()
	const { clientSummary } = useSelector((state) => state.admin2.clients)
	const [dataLoaded, setDataLoaded] = useState(false)
	const [gettingBackDocs, setGettingBackDocs] = useState(false)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [blockModalOpen, setBlockModalOpen] = useState(false)
	const [unblockModalOpen, setUnblockModalOpen] = useState(false)
	const [closeAccountModalOpen, setCloseAccountModalOpen] = useState(false)
	const deletingClientStatus = useActionStatus(clientDelete)
	const blockingClientStatus = useActionStatus(clientBlock)
	const unblockingClientStatus = useActionStatus(clientUnblock)
	const closingAccountStatus = useActionStatus(accountClose)
	const [client, setClient] = useState<ClientSummary>()
	const [fxActive, setFxActive] = useState(false)
	let [start, setStart] = useState(false)
	const activatingAccountStatus = useActionStatus(activateProduct)
	const approvingSignedDocsStatus = useActionStatus(signedDocApprove)
	let closedAccount = client?.status == 'CLOSED'
	useEffect(() => {
		if(clientSummary.length === 1) {
			setClient(clientSummary[0])
			setFxActive(clientSummary[0].activeProducts?.includes('FX') ? true : false)
		}
	}, [clientSummary]);
	useEffect(() => {
		if(dataLoaded === false && envs.accountType !== '') {
			dispatch(signedDocsGet({params: {id: envs.profileId}}))
	        setDataLoaded(true);
		}
        
    }, [envs.accountType]);
    useEffect(() => {
		dispatch(setFormUpdated({updated: false, form: []}))
  		return () => {
            setStart(false);
            
        }
    }, []);
    useEffect(() => {
  		if(gettingBackDocs === true) {
  			setGettingBackDocs(false);
  		}
    }, [gettingBackDocs]);

    useEffect(() => {
		let stateEl = products.productActivated;
		if(activatingAccountStatus.isError) {
			message.error(uppercaseFirstLetter(stateEl.message));
			dispatch(activateProductClear({}));
		} else {
			if(activatingAccountStatus.isFullfilled && stateEl.isActive === 200) {
				message.success(uppercaseFirstLetter('Account succesfully created'));
				dispatch(clientGet({params: {id: envs.profileId}}))
				dispatch(activateProductClear({}));
			}
		}
    }, [activatingAccountStatus, products.productActivated]);

	useEffect(() => {
		if(deletingClientStatus.isFullfilled) {
			message.success('This Client was successfully deleted')
			dispatch(clientDeleteClear({}))
			let tabEl = navEl.nav.activeListingTab ?  navEl.nav.activeListingTab : ''
			navigate(adminSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + tabEl)
		}
		if(deletingClientStatus.isError) {
			message.error('Some error occurred while deleting this Client')
			dispatch(clientDeleteClear({}))

		}
	}, [deletingClientStatus])

	useEffect(() => {
		if(blockingClientStatus.isFullfilled) {
			message.success('This Client was successfully blocked')
			dispatch(clientGet({params: {id: envs.profileId}}))
			dispatch(clientBlockClear({}))
		}
		if(blockingClientStatus.isError) {
			message.error('Some error occurred while blocking this Client')
			dispatch(clientBlockClear({}))

		}
	}, [blockingClientStatus])

	useEffect(() => {
		if(unblockingClientStatus.isFullfilled) {
			message.success('This Client was successfully unblocked')
			dispatch(clientGet({params: {id: envs.profileId}}))
			dispatch(clientUnblockClear({}))
		}
		if(unblockingClientStatus.isError) {
			message.error('Some error occurred while unblocking this Client')
			dispatch(clientUnblockClear({}))

		}
	}, [unblockingClientStatus])

	useEffect(() => {
		if(closingAccountStatus.isFullfilled) {
			message.success('This account was successfully closed')
			dispatch(clientGet({params: {id: envs.profileId}}))
			dispatch(accountCloseClear({}))
		}
		if(closingAccountStatus.isError) {
			message.error('Some error occurred while closing this acount')
			dispatch(accountCloseClear({}))

		}
	}, [closingAccountStatus])

	useEffect(() => {
		if(approvingSignedDocsStatus.isError) {
			return
		} else {
			if(approvingSignedDocsStatus.isFullfilled) {
				dispatch(clientGet({params: {id: envs.profileId}}))
			}
		}
    }, [approvingSignedDocsStatus])

    const startAccount = () => {
		if(envs.clientId) {
    		dispatch(activateProduct({params: { id: envs.clientId}, data: {product: 'FX'}}))
		}
    }
	const handleDeleteConfirm = () => {
		dispatch(clientDelete({params: {clientId: envs.profileId}}))
		setDeleteModalOpen(false)
	}

	const handleDeleteCancel = () => {
		setDeleteModalOpen(false)
	}
	const handleBlockConfirm = () => {
		dispatch(clientBlock({params: {clientId: envs.clientId}}))
		setBlockModalOpen(false)
	}

	const handleBlockCancel = () => {
		setBlockModalOpen(false)
	}
	const handleUnblockConfirm = () => {
		dispatch(clientUnblock({params: {clientId: envs.clientId}}))
		setUnblockModalOpen(false)
	}

	const handleUnblockCancel = () => {
		setUnblockModalOpen(false)
	}
	const handleCloseAccountConfirm = ({shouldSendEmail = false, wording = ''}) => {
		dispatch(accountClose({params: {clientId: envs.clientId}, data: {shouldSendEmail, emailWording: wording}}))
		setCloseAccountModalOpen(false)
	}

	const handleCloseAccountCancel = () => {
		setCloseAccountModalOpen(false)
	}
	const capitalize = (s?: string) => (s && s[0].toUpperCase() + s.slice(1).toLocaleLowerCase()) || ""
	return (
		<KycLayout nav={{hideNav: true, hideSteps: true, navSubpage: 'relation', navPage: NavItem.CLIENT_PROFILES }}>
			{start ? (
				<SuccessInfo linkAction={() => setStart(false)} linkEl={envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/relation'} linkText="See relation details">
					<p>Business Relation started successfully</p>
				</SuccessInfo>
			) : (
			<>
				<Row gutter={30} justify='space-between' style={{'position': 'relative', 'top' : '-16px'}}>
					<Col>
						<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Client status: 
							<Text type={client?.status === 'VERIFIED' ? "success" : "secondary"}><b> {client?.status === 'PENDING_KYC' ? 'Pending KYC' : capitalize(client?.status)}</b></Text>
						</Text>
					</Col>
				</Row>
				<Row gutter={30} justify='space-between'>
					<Col>
						<Text style={{'fontSize': '25px', 'position': 'relative', 'fontWeight':'bold'}}>FX account: 
							<Text type={fxActive ? "success" :  "secondary"}><b> {fxActive ? 'Created' : 'Not created'}</b></Text>
							{!fxActive && !closedAccount &&
								<span style={{position: 'relative', left: 10, top: '-5px'}}>
									<ButtonUpperSuccess className='btn-sm' onClick={startAccount} btnType={'default'}>Create FX Account</ButtonUpperSuccess>
								</span>
							}
						</Text>
					</Col>
				</Row>
				<Divider style={{marginBottom: 0}} />
	            <Row>
	            	<Col span={24}>
	        			{envs.accountType !== '' && gettingBackDocs === false &&
	        				<>
								<br />
								<br />
								<SignedKYCUpload disabled={false} />
							</>
						}
	            	</Col>
	            </Row>
            </>
            )}
			<DeleteClientModal open={deleteModalOpen} handleOk={handleDeleteConfirm} handleCancel={handleDeleteCancel} />
			<BlockClientModal open={blockModalOpen} handleOk={handleBlockConfirm} handleCancel={handleBlockCancel} />
			<UnblockClientModal open={unblockModalOpen} handleOk={handleUnblockConfirm} handleCancel={handleUnblockCancel} />
			<CloseAccountModal open={closeAccountModalOpen} handleOk={handleCloseAccountConfirm} handleCancel={handleCloseAccountCancel} />
			{(client?.kycStatus === 'NEW' || (!closedAccount && client?.status !== 'BLOCKED') || (!closedAccount && client?.status === 'BLOCKED') || !closedAccount) &&
				<StickyNav level={2}>
					<Row justify={'space-between'}>
						<Col></Col>
						<Col>
							{client?.kycStatus == 'NEW' && <ButtonUpper className='btn-warning' style={{marginRight: 10}} onClick={() => setDeleteModalOpen(true)}>Delete client</ButtonUpper>}
							{!closedAccount && client?.status !== 'BLOCKED' && <ButtonUpper btnType='dashed' className='btn-dashed' style={{marginRight: 10}} onClick={() => setBlockModalOpen(true)}>Block client</ButtonUpper>}
							{!closedAccount && client?.status === 'BLOCKED' && <ButtonUpper btnType='dashed' className='btn-dashed' style={{marginRight: 10}} onClick={() => setUnblockModalOpen(true)}>Unblock client</ButtonUpper>}
							{!closedAccount && <ButtonUpper btnType='primary' ghost  onClick={() => setCloseAccountModalOpen(true)}>Close account</ButtonUpper>}
						</Col>
					</Row>
				</StickyNav>
			}
		</KycLayout>
			 
	)
}

export default BusinessRelation

import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { companyNotesGet } from "../asyncThunks/companyNotesGet";
import { individualNotesGet } from "../asyncThunks/individualNotesGet";
import { companyNotePost } from "../asyncThunks/companyNotePost";
import { individualNotePost } from "../asyncThunks/individualNotePost";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { reduce } from "lodash";


type State = {
  companyNotes: any,
  companyNote: any,
  individualNotes: any,
  individualNote: any,
};

const initialState: State = {
  companyNotes: [],
  companyNote: [],
  individualNotes: [],
  individualNote: []
};

type CaseReducers<State> = {
  companyNotePostClear: CaseReducerType<State, object>;
  companyNotesGetClear: CaseReducerType<State, object>;
  individualNotePostClear: CaseReducerType<State, object>;
  individualNotesGetClear: CaseReducerType<State, object>;
};

export const notesSlice = createSlice<State, CaseReducers<State>>({
  name: "notes",
  initialState,
  reducers: {
    companyNotePostClear: (state, { payload }) => {
      state.companyNote = initialState.companyNote
    },
    individualNotePostClear: (state, { payload }) => {
      state.individualNote = initialState.individualNote
    },
    companyNotesGetClear: (state, { payload }) => {
      state.companyNotes = initialState.companyNotes
    },
    individualNotesGetClear: (state, { payload }) => {
      state.individualNotes = initialState.individualNotes
    },
  },
  extraReducers: (builder) => {
    builder.addCase(companyNotesGet.fulfilled, (state, action) => {
      state.companyNotes = action.payload;
    });
    builder.addCase(companyNotePost.fulfilled, (state, action) => {
      state.companyNote = action.payload;
    });
    builder.addCase(individualNotesGet.fulfilled, (state, action) => {
      state.individualNotes = action.payload;
    });
    builder.addCase(individualNotePost.fulfilled, (state, action) => {
      state.individualNote = action.payload;
    });
  },
});

export const { companyNotePostClear, individualNotePostClear, companyNotesGetClear, individualNotesGetClear } = notesSlice.actions;
export default notesSlice.reducer;

import { Space } from 'antd'
import { FC, ReactNode } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import { themeColor, themeScreen } from 'styles/themeStyles'
import styled from 'styled-components'

const Fixed = styled.div`
  	position: fixed;
  	z-index: 10;
	width: 100%;
	.content {
		max-width: ${themeScreen.maxContent};
	}
  	&.no-stick {
  		position: relative;
		bottom: 0!important;
  	}
`

const NavSpace = styled(Space)`
	background-color: #fff;
	padding: 15px 30px;
	margin-left: -30px;
	margin-right: -30px;
`

type NProps = {
	children: ReactNode,
    level?: number
}


const StickyNav: FC<NProps> = ({level, children}) => {
	const envs = useSelector((state) => state.general.envs)
	return (
        <Fixed  style={{'bottom': `${level && level > 1 ? envs.admin ? '57px' : '0' : '0'}`}}>
            <div className='content' style={{ 'borderTop': `2px solid ${themeColor.grayWhite}`, background: '#fff' }}>
                <NavSpace direction='vertical' size={'small'}>
                    {children}
                </NavSpace>
            </div>
        </Fixed>
			 
	)
}

export default StickyNav

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { api2 } from "api/kyc/api";
import { Paths } from "api/kyc/generated/client2";

type PathParameters = Paths.ActivateProduct.PathParameters
type RequestBody = Paths.ActivateProduct.RequestBody
type Response = Paths.ActivateProduct.Responses.$200

export const activateProduct: AsyncThunkActionType<
  Response,
  AsyncThunkDataType<PathParameters, RequestBody, "params">
> = createAsyncThunk(
  "activateProduct",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.activateProduct(params, data, config);
      return response.status as Response
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

import { createSlice } from "@reduxjs/toolkit"
import { CaseReducerType } from "../../store"
import { accountClose } from "../asyncThunks/accountClose"

type State = {
    accountClosed: boolean,
};


const initialState: State = {
    accountClosed: false
};

type CaseReducers<State> = {
    accountCloseClear: CaseReducerType<State, object>;
};

export const accountSlice = createSlice<State, CaseReducers<State>>({
    name: "accountSlice",
    initialState,
    reducers: {
        accountCloseClear: (state, {payload}) => {
            state.accountClosed = initialState.accountClosed
        }
    },
    extraReducers: (builder) => {
        builder.addCase(accountClose.fulfilled, (state, action) => {
            state.accountClosed = true
        });
    },
});

export const { accountCloseClear } = accountSlice.actions;
export default accountSlice.reducer;

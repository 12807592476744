import { FC } from "react"
import Text from 'antd/es/typography/Text'
import ActionModalComponent from "modules/kyc-front/user-actions/ActionModalComponent"

type BProps = {
	open: boolean,
	handleOk: (value: any) => void,
	handleCancel: (value: any) => void
}

const UnblockClientModal: FC<BProps> = ({open, handleOk, handleCancel}) => (
	<ActionModalComponent
		open={open}
		handleOk={handleOk}
        name='Do you want to unblock this client?'
		okText='Unblock'
		handleCancel={handleCancel}
		cancelText='Cancel'
	>
        <br />
        <Text>Client will be again be able to use the system as normally.</Text><br /><br />
        <Text>All transactions they started during the time they were blocked <br /> should be reviewed and resumed manually.<br /></Text>
  </ActionModalComponent>
)

export default UnblockClientModal
import { NavItem } from 'modules/kyc-front/kyc-layout/KycLayout'
import { moment } from '../kyc-front/ui-elements/FormDate'
import { Button, Alert } from 'antd'
import {
	MessageTwoTone, CloseOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'
import { dispatch } from '../../store/store'
import { useSelector } from '../../hooks/useSelector'
import { setEnvs } from '../../store/kyc-front/slices/envsSlice'
import styled from 'styled-components'
import { themeColor } from '../../styles/themeStyles'
import { adminMessagesGet } from '../../store/kyc-backend/asyncThunks/adminMessagesGet'
import { useActionStatus } from '../../hooks/useActionStatus'
import { themeScreen } from '../../styles/themeStyles'
import { messagesGet } from 'store/kyc-front/asyncThunks/messagesGet'
import { MessageResponse, messageViewedPutClear } from 'store/kyc-front/slices/messagesSlice'
import { messageViewedPut } from 'store/kyc-front/asyncThunks/messageViewedPut'


const CustomMessage = styled.div`
	background-color: #fff;
  	border: 1px solid #fff;
	.ant-alert {
		width: 100%;
    	height: 100%;
    	position: relative;
    	margin: 0 auto;
    	padding: 15px;
    	max-width: calc(1400px + 2%);
    	border: none;
    	background-color: #fff;
	}
	.ant-alert-message {
		margin-bottom: 0;
		display: inline-block;
	}
	.ant-alert-close-icon {
		position: relative;
    	left: 20px;
	}
	.ant-alert-icon {
    	margin-right: 15px;
    	font-size: 24px;
	}
	.ant-alert-description {
		display: inline-block;
		font-weight: 600;
		font-size: 16px;
		margin-left: 5px;
	}
	&.standalone {
		position: fixed;
		position: sticky;
    	top: 65px;
    	width: 100%;
    	z-index: 99;
    	border-bottom: 1px solid ${themeColor.grayWhite};
    	.ant-alert {
    		padding-left: 0;
    		padding-right: 0;
    		max-width: ${themeScreen.maxContent};
    	}
	}
`

interface CProps {
	config?: IProps
}

interface IProps {
	apiURL?:  string,
	adminSuffix?: string,
	token?: string,
	profileId: string,
	clientId: string,
	type?: string,
	env?: string,
	accountType: 'PRIVATE' | 'BUSINESS',
	routeSuffix: string
}



const KycMessage: FC<CProps> = ({
	config
}) => {
	const envs = useSelector((state) => state.general.envs)
	const nav = useSelector((state) => state.general.nav)
	let { env, type, profileId, clientId } = config ? config : envs
	const messages = useSelector((state) => state.user2.messages)
	const gettingStatus = useActionStatus(messagesGet)
	const gettingStatusAdmin = useActionStatus(adminMessagesGet)
	const [getting, setGetting] = useState(true)
	const [shown, setShown] = useState(false)
	const [envsLoaded, setEnvsLoaded] = useState(false)
	const [redirectPending, setRedirectPending] = useState(false)
	const [messageList, setMessageList] = useState<any>([])
	const adminInbox = useSelector((state) => state.admin2.adminInbox)
	let navigate = useNavigate();
	useEffect(() => {
		if(envs.admin) {
			dispatch(adminMessagesGet({params: {}}))
		} else {
			dispatch(messagesGet({params: {clientId: envs.clientId}}))
		}
	}, [])
	useEffect(() => {
		if(envsLoaded === false && profileId !== '') {
			if(config) {
				let { env, type, token, profileId, clientId, routeSuffix, accountType, apiURL, adminSuffix } = config
				dispatch(setEnvs({type, env, profileId, clientId, routeSuffix, accountType, apiURL, adminSuffix}))
			}
			setGetting(false)
			setEnvsLoaded(true)
		}
	}, [config])
	useEffect(() => {
		if(envs.admin) {
			if(gettingStatusAdmin.isPending && shown === true) {
				setShown(false);
			}
			if(gettingStatusAdmin.isFullfilled && shown === false) {
				let messageContent: MessageResponse[] = [] 
				if(adminInbox.messages) {
					messageContent = adminInbox.messages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy)
				}
		        let messagesResponse = [...messageContent].sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse()
		        setMessageList(messagesResponse)
		        setShown(true)
			}
		}
	}, [gettingStatusAdmin])
	useEffect(() => {
	    if(!gettingStatus.isFullfilled && envs.clientId !== '' && getting === false) {
			if(envs.admin) {
				dispatch(adminMessagesGet({params: {}}))
			} else {
				dispatch(messagesGet({params: {clientId: envs.clientId}}));
			}
	      	setGetting(true);
	    }
	}, [envs]);
	useEffect(() => {
		if(gettingStatus.isFullfilled && shown === false) {
			let messageContent = messages.profileMessages
			let messagesElems:any[] = []
			messageContent.forEach((message: MessageResponse) => {
			if(!message.viewedAt && message.createdBy) {
				messagesElems.push(message)
			}
			})
			let messagesResponse = [...messagesElems].sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse();
			setMessageList(messagesResponse)
			setShown(true);
			if(redirectPending === true) {
				setRedirectPending(true);
				navigate(envs.routeSuffix + `/${NavItem.CLIENT_INBOX}`);
			}
		} 
	}, [messages]);
	useEffect(() => {
	    if(messages.messageViewed.read === 204) {
	        dispatch(messageViewedPutClear({params: {}}));
	        getAllMessages();
	    }
	}, [messages.messageViewed])
	const getAllMessages = () => {
		if(envs.admin) {
			dispatch(adminMessagesGet({params: {}}));
		} else {
			dispatch(messagesGet({params: {clientId: envs.clientId}}));
		}
	}
	const redirectToInbox = (id: string) => {
		if(type === 'module') {
			dismiss(id);
		}
		navigate(envs.routeSuffix + `/${NavItem.CLIENT_INBOX}`);
	}
	const redirectToInboxAdmin = () => {
		navigate(envs.routeSuffix + `/${NavItem.MESSAGES}`);
	}
	const dismiss = (id: string, message?: any) => {
		setRedirectPending(false);
		if(envs.admin) {
			dispatch(messageViewedPut({params: { messageId: message?.messageId, clientId: message.clientId}})).then(() => setShown(false))
		} else {
			dispatch(messageViewedPut({params: {messageId: id}})).then(() => setShown(false))
		}
	}
	return (
		<>
			{messageList.length ? (
				<CustomMessage className={envs.type ==='standalone' ? 'standalone' : ''}>
					<Alert
				      message="You've got new message: "
				      description={messageList[0].subject.startsWith('update') ? "Bitclear Admin has updated Your profile" : messageList[0].subject}
				      showIcon={true}
				      icon={<MessageTwoTone />}
				      type="info"
				      action={
				      	<>
				      		{nav.nav.navPage !== 'messages' &&
						      	<Button size="small" type='primary' style={{'marginRight': '5px', 'width': '115px'}} onClick={envs.admin ? () => redirectToInboxAdmin() : () => redirectToInbox(messageList[0].messageId)}>
						          	{'Read message'}
						        </Button>
						    }
					        <Button size="small" style={{'width': '110px'}} onClick={() => dismiss(messageList[0].messageId, messageList[0])}>
					          	Close <CloseOutlined />
					        </Button>
				        </>
				      }
				    />
				</CustomMessage>
			) : (<></>)}
		</>
	)
}



export default KycMessage
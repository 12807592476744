import { createAsyncThunk } from "@reduxjs/toolkit";
import { api2 } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "api/kyc/generated/client2";

interface Document {
    id: string;
    fileName: string;
    mimeType: string;
}

type DocsResponse = Document[]
type PathParameters = Paths.GetSignedDocuments.PathParameters

export const signedDocsGet: AsyncThunkActionType<
  DocsResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("signedDocsGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api2.getSignedDocuments(data.params, null, data?.config);
    return response.data as DocsResponse
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

import { useSelector } from './useSelector'

type IReturn = {
	isApprovalDisabled: boolean
	disabledApprovalMsg? : string,
	isKycRejected: boolean
}
export const useKycApprove = (): IReturn => {
	const { kycApplication } = useSelector(state=>state.user2.kycApplication)
	const isApprovalDisabled = kycApplication.status !== 'SUBMITTED'
	const isKycAccepted = kycApplication.status === 'ACCEPTED'
	const isKycRejected = kycApplication.status === 'REJECTED'
	const disabledApprovalMsg = isApprovalDisabled ? isKycAccepted ? 'Application has been approved' : isKycRejected ? 'Application has been rejected' : 'Application is not submitted': undefined
	
	return {
		isApprovalDisabled,
		disabledApprovalMsg,
		isKycRejected
	}
}
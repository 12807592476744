import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { api2 } from "api/kyc/api";
import { Paths } from "api/kyc/generated/client2"
import { RiskProfileType } from "../slices/riskSlice";

type PathParameters = Paths.CreateManualEvaluation.PathParameters
type ReqBody = Paths.CreateManualEvaluation.RequestBody
type Response = RiskProfileType


export const riskProfileUpdate: AsyncThunkActionType<
    Response,
    AsyncThunkDataType<PathParameters, ReqBody, "params">
> = createAsyncThunk(
  "riskProfileUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.createManualEvaluation(params, data, config);
      return response.data as Response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

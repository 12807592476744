import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { api2 } from "api/kyc/api";
import { Paths } from "api/kyc/generated/client2";

type PathParameters = Paths.DeleteRiskProfile.PathParameters
type ArchiveResponse = Paths.DeleteRiskProfile.Responses.$204


export const riskProfileDelete: AsyncThunkActionType<
  ArchiveResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk(
  "riskProfileDelete",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.deleteRiskProfile(params, null, config);
      return response.status === 204 ? true : false as ArchiveResponse
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

import { createSlice } from "@reduxjs/toolkit"
import { docGet } from "store/kyc-front/asyncThunks/docGet"
import { CaseReducerType } from "../../store"

type State = {
    docUrl: string,
};

const initialState: State = {
    docUrl: ''
};

type CaseReducers<State> = {
    docGetClear: CaseReducerType<State, object>;
};

export const kycApplicationSlice =  createSlice<State, CaseReducers<State>>({
    name: "kycApplication",
    initialState,
    reducers: {
        docGetClear: (state, { payload }) => {
            state.docUrl = initialState.docUrl
        }
    },
    extraReducers: (builder) => {
        builder.addCase(docGet.fulfilled, (state, action) => {
            state.docUrl = action.payload;
        });
    },
});

export const { docGetClear } = kycApplicationSlice.actions;
export default kycApplicationSlice.reducer;

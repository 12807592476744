import { FC, useEffect, useRef, useState } from "react"
import Text from 'antd/es/typography/Text'
import TextArea from 'antd/es/input/TextArea'
import { FormCheckboxRadioFormStandalone } from "modules/kyc-front/ui-elements/FormCheckboxRadio"
import styled from "styled-components"
import ActionModalComponent from "modules/kyc-front/user-actions/ActionModalComponent"

type DProps = {
	open: boolean,
	handleOk: (value: any) => void,
	handleCancel: (value: any) => void
}

const FormCheckboxRadioCustom = styled.div`
    margin-bottom: 10px;
    .checkContainer .ant-col {
        margin-bottom: 10px;
    }
`


const defaultText = `This is an automated notification to inform you that we have successfully closed your account with us, as per our prior communication. We appreciate your trust in our services and are grateful for the opportunity to have served you.
If you have any further inquiries or need assistance with any other matters, please do not hesitate to contact us`

const CloseAccountModal: FC<DProps> = ({open, handleOk, handleCancel}) => {
    const [description, setDescription] = useState(defaultText);
    const [shouldSendEmail, setShouldSendEmail] = useState(false);
    const [height, setHeight] = useState(0);
    const [opacity, setOpacity] = useState<0 | 1>(0);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const handleDescriptionChange = (el: any) => {
        setDescription(el.target.value);
    };

    const handleToggle = () => {
        const newShouldSendEmail = !shouldSendEmail;
        setShouldSendEmail(newShouldSendEmail);
        
        if (contentRef.current) {
            setHeight(newShouldSendEmail ? contentRef.current.scrollHeight : 0); 
        }
        setOpacity(newShouldSendEmail ? 1 : 0); 
    };

    useEffect(() => {
        if (contentRef.current) {
            setHeight(shouldSendEmail ? contentRef.current.scrollHeight : 0);
        }
    }, [shouldSendEmail]);
    return (
        <ActionModalComponent
            open={open}
            handleOk={() => handleOk({shouldSendEmail, wording: description})}
            name='Do you want to close this account?'
            okText='Close account'
            handleCancel={handleCancel}
        >
                <Text>The client will not be able to log in anymore.</Text>
                <br />
                <Text>The record of this client will be kept as a closed account.</Text>
                <br />
                <br />
                <FormCheckboxRadioCustom>
                    <FormCheckboxRadioFormStandalone label='' id={'shouldSendEmail'} type='radio' onChange={handleToggle}
                        options={[{
                                label: 'Send email to the client with following information',
                                value: 'true'
                            },
                            {
                                label: 'Do not inform the client',
                                value: 'false'
                            }
                        ]
                    } value={shouldSendEmail.toString()} />
                </FormCheckboxRadioCustom>
                <div
                    ref={contentRef}
                    style={{
                        overflow: 'hidden',
                        transition: 'height 0.5s ease, opacity 0.5s ease',
                        height: `${height}px`,
                        opacity: opacity,
                }}>
                    <TextArea style={{resize: "none", marginTop: 10, marginBottom: 15}} onChange={handleDescriptionChange} rows={10} value={description}></TextArea>
                    <br />
                </div>
        </ActionModalComponent>
    )
}

export default CloseAccountModal
import Title from 'antd/es/typography/Title'
import { useNavigate } from 'react-router-dom'
import { moment } from '../../../kyc-front/ui-elements/FormDate'
import Text from 'antd/es/typography/Text'
import { Row, Col, Divider, Badge, message, Tooltip, Drawer } from 'antd'
import { useActionStatus } from '../../../../hooks/useActionStatus'
import { dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import { FC, useEffect, useState } from 'react'
import { companyProfileBackGet } from '../../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from '../../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { 
	
	riskProfileCreateClear,
	RiskProfileType,
	riskProfilesGetClear,
	riskProfileUpdateClear,
	riskProfileApproveClear
} from '../../../../store/kyc-backend/slices/riskSlice'
import { ButtonUpper } from '../../../kyc-front/ui-elements/Buttons'
import { SuccessIcon } from '../../../kyc-front/ui-elements/SuccessInfo'
import { themeColor } from '../../../../styles/themeStyles'
import RiskProfileHistoryTable from './RiskProfileHistoryTable'
import RiskProfileForm from './RiskProfileForm'
import RiskProfilePrint from './RiskProfilePrint'
import { setFormUpdated } from '../../../../store/kyc-front/slices/navSlice'
import styled from 'styled-components'
import KycLayout, { NavItem } from '../../../kyc-front/kyc-layout/KycLayout'
import { riskProfilesGet } from 'store/kyc-backend/asyncThunks/riskProfilesGet'
import { riskProfileCreate } from 'store/kyc-backend/asyncThunks/riskProfileCreate'
import { riskProfileUpdate } from 'store/kyc-backend/asyncThunks/riskProfileUpdate'
import { riskProfileApprove } from 'store/kyc-backend/asyncThunks/riskProfileApprove'
import { Components } from 'api/kyc/generated/client2'

const CustomBadge = styled(Badge)`
	.ant-badge-count {
		font-weight: bold;
    	padding: 5px 10px;
    	height: auto;
    	font-size: 15px;
	}
	&.success .ant-badge-count {
		background-color: ${themeColor.green};
	}
	&.warning .ant-badge-count {
		background-color: orange;
	}
`

const CenteredCol = styled(Col)`
	text-align: center;
	margin-bottom: 0;
	margin-top: 40px;
`

const Summary = styled.p`
	span {
		font-size: 16px;
		display: inline-block;
		&.value {
			width: auto;
			display: inline-block;
			margin-left: 5px;
		}
	}
`

interface RProps {
    hasId?: boolean,
	riskProfileId?: string,
	isArchived?: boolean
}

export type SnapStateType = {
	isApproved: boolean,
	isDraft: boolean,
	isLatestApproved: boolean,
	isEditable: boolean
}

type Approval = Components.Schemas.Approval

interface SProps {
    totalScore: number,
    printRender: boolean
}

export const RiskStatus: FC<SProps> = ({totalScore, printRender}) => (
	<div className='currentScore' style={{marginBottom: 10}}>
		<Text style={printRender ? {display: 'inline-block', width: 120, fontWeight: 'bold'} : {fontSize: 25, fontWeight:'bold'}}>Current Score: </Text>
		<Text style={{fontSize: 25, fontWeight: 'bold'}} type={totalScore < 3 ? 'success' : 'warning'}> <CustomBadge showZero={true} className={totalScore < 3 ? 'success' : 'warning'} overflowCount={9999} count={totalScore} /><span style={{textTransform: 'uppercase', marginLeft: printRender ? 0 : 5, fontSize: 22}}>{printRender ? <span>{totalScore}</span> : <></>} {totalScore < 3 ? 'Normal risk' : 'Increased Risk'}</span></Text>
	</div>
)

const RiskProfile: FC<RProps> = ({riskProfileId}) => {
	const navigate = useNavigate();
	const envs = useSelector((state) => state.general.envs);
	const riskProfile = useSelector((state) => state.admin2.riskProfiles);
	const { riskProfiles, riskProfileCreated, riskProfileUpdated, riskProfileApproved } = riskProfile
	const { clientSummary } = useSelector((state) => state.admin2.clients)
	const isBusiness = envs.accountType === 'BUSINESS';
	const [dataLoaded, setDataLoaded] = useState(false)
	const [disableModal, setDisableModal] = useState(false)
	const [popupVisible, setPopupVisible] = useState(false);
	const [visible, setVisible] = useState(false);
	const [initialDataLoaded, setInitialDataLoaded] = useState(false);
	const [createNew, setCreateNew] = useState(false);
	const [isBack, setBack] = useState(false);
	const [shouldReload, setShouldReload] = useState(false);
	const [shouldReloadListing, setShouldReloadListing] = useState(true);
	const [finalApprove, setFinalApprove] = useState(false);

	const [submittingComment, setSubmittingComment] = useState([]);
	const riskProfileGettingStatus = useActionStatus(riskProfilesGet)
	const riskProfileCreatingStatus = useActionStatus(riskProfileCreate)
	const riskProfileUpdatingStatus = useActionStatus(riskProfileUpdate)
	const riskProfileApprovingStatus = useActionStatus(riskProfileApprove)
	const [rPData, setRPData] = useState<RiskProfileType>({})
	const [isEditable, setIsEditable] = useState<boolean>(false)
	const [isApproved, setIsApproved] = useState<boolean>(false)
	useEffect(() => {
		dispatch(setFormUpdated({updated: false, form: []}));
    }, [])


	useEffect(() => {
		if(dataLoaded === false && envs.accountType !== '') {
			if(isBusiness) {
	        	dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
	        }
	        if(envs.accountType === 'PRIVATE') {
	        	dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
	        }
	        setDataLoaded(true);
		}
        
    }, [envs.accountType])

	useEffect(() => {
		if(riskProfileCreatingStatus.isFullfilled && riskProfileCreated.id !== '') {
			navigate(envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/risk-profile/' + riskProfileCreated.id)
			message.success('Profile was successfully created')
			setShouldReloadListing(true)
			setDataLoaded(false)
			dispatch(riskProfileCreateClear({}))
			dispatch(riskProfilesGet({params: {clientId: envs.profileId}}))
		}
	}, [riskProfileCreatingStatus])

	useEffect(() => {
		if(riskProfileApprovingStatus.isFullfilled) {
			message.success('Profile was successfully approved')
			dispatch(riskProfileApproveClear({}))
			dispatch(riskProfilesGet({params: {clientId: envs.profileId}}))
		}
	}, [riskProfileApproved])
   
    useEffect(() => {
    	if(createNew) {
			dispatch(riskProfileCreate({params: null, data: {clientId: envs.profileId}}))
			setCreateNew(false)
    	}
    }, [createNew]);
    useEffect(() => {
    	if(isBack && dataLoaded === false) {
    		//callInitialFunctions();
    		setBack(false)
    	}
    	
    }, [dataLoaded]);
    useEffect(() => {
    	if(shouldReload) {
    		setShouldReload(false);
    	}
    	
    }, [shouldReload]);
    
    useEffect(() => {
    	return(() => {
			dispatch(riskProfilesGetClear({}))
    	});
    }, []);

	useEffect(() => {
		setDisableModal(riskProfiles.find((el: any) => el?.status === 'DRAFT') ? true : false)
		if(riskProfileGettingStatus.isError) {
			message.error('Some error occured while getting this element')
		}
		if(riskProfileGettingStatus.isFullfilled && riskProfiles.length) {
			
			let targetSnapshot = riskProfileId ? riskProfiles.find((elem: any) => elem.id === riskProfileId) : undefined
			if(targetSnapshot) {
				setRiskProfileData(targetSnapshot)
			} else {
				if(!riskProfileId) {
					const approvedProfile = riskProfiles.find((elem: any) => elem?.status === 'APPROVED')
					setRiskProfileData(approvedProfile ? approvedProfile : riskProfiles[0])
				}
			}
			setDataLoaded(true)
			
		}
	}, [riskProfileGettingStatus])

	useEffect(() => {
		if(riskProfileUpdatingStatus.isError) {
			message.error('Some error occured while updating this element')
			dispatch(riskProfileUpdateClear({}))
		}
		if(riskProfileUpdatingStatus.isFullfilled && riskProfileUpdated.id) {
			message.success('This element was successfully updated')
			dispatch(riskProfileUpdateClear({}))
			dispatch(riskProfilesGet({params: {clientId: envs.profileId}}))
		}
	}, [riskProfileUpdatingStatus])

	const setRiskProfileData = (profileData: RiskProfileType) => {
        setRPData(profileData)
		setIsEditable(profileData.status === 'DRAFT')
		setIsApproved(profileData.status === 'APPROVED')
    }
	const setRiskProfileDataOld = (profileData:any) => {
        setRPData(profileData)
    }
    const handleHistoryShow = (historicalData: any) => {
		setInitialDataLoaded(false);
		setRiskProfileData(historicalData.riskProfileSnapshot ? historicalData.riskProfileSnapshot : historicalData);
		setVisible(false);
	}
    const confirmNew = () => {
    	setPopupVisible(false);
    	newProfileClicked();
    }
    const showDrawer = () => {
	    setVisible(true);
	}

	const onClose = () => {
	    setVisible(false);
	}
	const newProfileClicked = () => {
        setDisableModal(true)
        setCreateNew(true)
    }
   	const handleSave = (id: string, message: string, isMet: string, criterionName: any) => {
		dispatch(riskProfileUpdate({params: {id: id},  data: {criterionName: criterionName.name, isMet: isMet === 'true', message: message}}))
	}
	const hideFinalApprove = () => {
    	setFinalApprove(false);
    	navigate(envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/risk-profile');

    }
	const latestApproval = (elem: Approval[] | undefined) => elem
  		?.filter(a => a.approvedAt)
  		.reduce((latest, current) => 
    		new Date(current.approvedAt!) > new Date(latest.approvedAt!) ? current : latest
  	, { approvedAt: "1970-01-01T00:00:00Z", approvedBy: "" });

	const clientName = clientSummary.length ? clientSummary[0].name ? clientSummary[0].name : 'Client name' : 'Client name'
	return (
        (<KycLayout nav={{hideNav: true, hideSteps: true, navSubpage: 'risk-profile', navPage: NavItem.CLIENT_PROFILES}}>
            {finalApprove ? (
				<div>
					<Row justify='center'>
						<CenteredCol>
							<SuccessIcon />
							<p><b>Approved!</b></p>
							<p>Your have succesfully approved this Risk Profile</p>
						</CenteredCol>
					</Row>
					<Row justify='center' style={{'marginTop': '20px', 'marginBottom': '40px'}}>
						<CenteredCol>
							<ButtonUpper onClick={hideFinalApprove} btnType='default'>View Risk Profile</ButtonUpper>
						</CenteredCol>
					</Row>
			    </div>
		    ) : (
				<Row gutter={30}>
					<Col span={24}>
						<Row justify='space-between' style={{'position': 'relative', 'top' : '-16px'}}>
							{riskProfiles.length ? (
								<>
									<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Risk Profile status: 
										<Text style={{color: rPData?.status === 'APPROVED' ? themeColor.green : rPData?.status === 'DRAFT' ? themeColor.blue : themeColor.blueGray}}><b> {rPData?.status ? rPData?.status : 'No status'}</b></Text>
									</Text>
								</>
							) : (
								<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>No risk profile</Text>
							)}
							<div>
								<span style={{marginRight: 10}}><ButtonUpper btnType='default' disabled={riskProfiles.length < 1} onClick={showDrawer}>History</ButtonUpper></span>
								<Tooltip title={disableModal && 'Client already has a draft profile created'}><ButtonUpper btnType='primary' onClick={confirmNew} disabled={disableModal}>New Profile</ButtonUpper></Tooltip>
							</div>
						</Row>
						{riskProfiles.length ? (
							<>
								<RiskStatus totalScore={rPData.totalScore ? rPData.totalScore : 0} printRender={false} />
								<Row className='hide-print'>
									<Col span={24} style={{marginTop: -20, marginBottom: 0}}>
										<Divider />
										<Title level={4} style={{'marginBottom': '20px', 'marginTop': '30px'}}>Profile Summary</Title>
										<Summary>
											<span>Created at:</span>
											<span className='value'><b>{rPData.createdAt === '' ? 'Not created' : moment(rPData.createdAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
										</Summary>
										{isApproved ? (
											<Summary>
												<span>Approved at:</span>
												<span className='value'><b>{moment(latestApproval(rPData.approvals)?.approvedAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
											</Summary>
										) : (<></>)
										}
									</Col>
								</Row>
								<Divider style={{marginBottom: 0}} />
							</>
						) : (<></>)}
						<Drawer title="Risk Profile History" size='large' placement="right" onClose={onClose} open={visible}>
					        <RiskProfileHistoryTable handleHistoryShow={handleHistoryShow} />
					    </Drawer>
						{riskProfiles.length ? (
							<RiskProfileForm 
								initialData={rPData} 
								isEditable={isEditable}
								handleSave={handleSave}
								submittingComment={submittingComment}
								printRender={false}
							>
								<div style={{marginBottom: 30, display: isApproved ? 'block' : 'none'}}> 
									<Divider />
									<Title level={3}>Download Risk Profile</Title>
									<RiskProfilePrint approvals={rPData.approvals} totalScore={rPData.totalScore ? rPData.totalScore : 0} name={clientName} id={envs.profileId}>
										<RiskProfileForm 
											initialData={rPData} 
											isEditable={isEditable}
											handleSave={handleSave}
											submittingComment={submittingComment}
											printRender={true}
										/>
									</RiskProfilePrint>
								</div>
							</RiskProfileForm>
						) : (<></>)}
					</Col>
				</Row>
			)}
        </KycLayout>)
    );
}

export default RiskProfile

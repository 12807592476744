import { FC } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import { signedDocsGet } from 'store/kyc-backend/asyncThunks/signedDocsGet'
import { signedDocCreateClear, signedDocDeleteClear, signedDocsGetClear } from 'store/kyc-backend/slices/signedDocsSlice'
import FormUpload from 'modules/kyc-front/ui-elements/FormUpload'
import { signedDocCreate } from 'store/kyc-backend/asyncThunks/signedDocCreate'
import { signedDocDelete } from 'store/kyc-backend/asyncThunks/signedDocDelete'

interface SProps {
    personType?: 'authorized-persons' | 'ubos' | 'individual' | 'company', 
    disabled?: boolean,
    isErrorMessage?: boolean
}

const SignedKYCUpload: FC<SProps> = ({ personType, disabled }) => {
    const envs = useSelector((state) => state.general.envs)
    const { docsList, newSignedDoc, docDeleted } = useSelector((state) => state.admin2.signedDocs)
    const docsListing = docsList
    return <FormUpload name='Signed KYC documents' disabled={disabled} uploadAdmin={true} docs={docsListing} /*isErrorMessage={errors?.OtherDocumentKind}*/ personId={envs.profileId} personType={personType}  maxSize={15} createdDoc={newSignedDoc} docCreate={signedDocCreate} docCreateClear={signedDocCreateClear} docsGet={signedDocsGet} docsGetParams={{id: envs.profileId}} docDelete={signedDocDelete} docDeleteClear={signedDocDeleteClear} docDeleted={docDeleted} docType={'KYCSignedDeclaration'} type={envs.isBusiness ? 'company' : 'individual'} shouldShowArchived={false} nonKycUpload={true} />
}

export default SignedKYCUpload

import { createSlice } from "@reduxjs/toolkit"
import { Components } from "api/kyc/generated/client2"
import { CaseReducerType } from "../../store"
import { ClientActionPoint, clientsActionPointsGet } from '../asyncThunks/clientsActionPointsGet'
import { clientsGet } from "../asyncThunks/clientsGet"
import { clientGet } from "../asyncThunks/clientGet"
import { clientDelete } from "../asyncThunks/clientDelete"
import { clientBlock } from "../asyncThunks/clientBlock"
import { clientUnblock } from "../asyncThunks/clientUnblock"

export type ClientSummary = Components.Schemas.ClientSummary

type State = {
    clientsList: ClientSummary[],
	clientsSearchListing: ClientSummary[],
	clientDeleted: boolean,
	clientBlocked: boolean,
	clientUnblocked: boolean,
	clientsActionPoints: ClientActionPoint[],
	clientSummary: ClientSummary[]
};


const initialState: State = {
    clientsList: [],
	clientsSearchListing: [],
	clientDeleted: false,
	clientBlocked: false,
	clientUnblocked: false,
	clientsActionPoints: [],
	clientSummary: []
};

type CaseReducers<State> = {
    clientsGetClear: CaseReducerType<State, object>;
	clientDeleteClear: CaseReducerType<State, object>;
	clientBlockClear: CaseReducerType<State, object>;
	clientUnblockClear: CaseReducerType<State, object>;
	clientGetClear: CaseReducerType<State, object>;
};

export const clientsSlice = createSlice<State, CaseReducers<State>>({
	name: "clientsSlice",
	initialState,
	reducers: {
		clientsGetClear: (state, {payload}) => {
			state.clientsList = initialState.clientsList
			state.clientsSearchListing = initialState.clientsSearchListing
		},
		clientDeleteClear: (state, {payload}) => {
			state.clientDeleted = initialState.clientDeleted
		},
		clientBlockClear: (state, {payload}) => {
			state.clientBlocked = initialState.clientBlocked
		},
		clientUnblockClear: (state, {payload}) => {
			state.clientUnblocked = initialState.clientUnblocked
		},
		clientGetClear: (state, {payload}) => {
			state.clientSummary = initialState.clientSummary
		}
	},
	extraReducers: (builder) => {
		builder.addCase(clientsGet.fulfilled, (state, action) => {
			let query = action.meta.arg?.params?.query
			if(query && query !== '') {
				state.clientsSearchListing = action.payload
			} else {
				state.clientsList = action.payload
				state.clientsSearchListing = []
			}
		});
		builder.addCase(clientsActionPointsGet.fulfilled, (state, action) => {
			state.clientsActionPoints = action.payload
		});
		builder.addCase(clientDelete.fulfilled, (state, action) => {
			state.clientDeleted = true
		});
		builder.addCase(clientBlock.fulfilled, (state, action) => {
			state.clientBlocked = true
		});
		builder.addCase(clientUnblock.fulfilled, (state, action) => {
			state.clientUnblocked = true
		});
		builder.addCase(clientGet.fulfilled, (state, action) => {
			state.clientSummary = action.payload
		});
	},
});

export const { clientsGetClear, clientDeleteClear, clientBlockClear, clientUnblockClear, clientGetClear } = clientsSlice.actions;
export default clientsSlice.reducer;

import { Typography, Skeleton } from 'antd'
import _ from 'lodash'
import Title from 'antd/es/typography/Title'
import { useSelector } from '../../../hooks/useSelector'
import { PageContent } from '../../layout/PageContent'
import { dispatch } from '../../../store/store'
import { FC, useEffect } from 'react'
import KycLayout, { NavItem } from '../../kyc-front/kyc-layout/KycLayout'
import { MessagesListing } from '../../kyc-front/ui-elements/Comment'
import { adminMessagesGet } from '../../../store/kyc-backend/asyncThunks/adminMessagesGet'
import { adminMessagesGetClear } from '../../../store/kyc-backend/slices/adminInboxSlice'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { clientsGet } from 'store/kyc-backend/asyncThunks/clientsGet'

const AdminMessages: FC = () => {
    const adminInbox = useSelector((state) => state.admin2.adminInbox)
    const clientsGetStatus = useActionStatus(clientsGet)
	useEffect(() => {
        dispatch(clientsGet())
    }, []);
	return (
        <KycLayout listingView={true} pageName='Messages' nav={{hideNav: true, step: -1, navPage: NavItem.MESSAGES}}>
    		<PageContent >
    			<Title level={2} style={{marginTop: -40, marginBottom: 0}}>Messages</Title> 
                {clientsGetStatus.isError ? (
                        <p>Error</p>
                ) : (
                    <>
                        {clientsGetStatus.isFullfilled ? (
                            <MessagesListing 
                                type='message' 
                                side='admin' 
                                listing={ adminInbox.messages }
                                adminInbox={true}
                            />
                        ) : (<Skeleton style={{marginTop: 30}} paragraph={{ rows: 10 }} active />)}
                    </>
                )}
    		</PageContent>
        </KycLayout>
	)
}

export default AdminMessages

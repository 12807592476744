import { createAsyncThunk } from "@reduxjs/toolkit";
import { api2 } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common"
import { Paths } from "api/kyc/generated/client2";

type PathParameters = Paths.GetRiskProfiles.QueryParameters
type Response = any

export const riskProfilesGet: AsyncThunkActionType<
  Response[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("riskProfilesGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api2.getRiskProfiles(data.params, null, data?.config);
    return response.data
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

import Title from 'antd/es/typography/Title'
import { moment } from '../../../kyc-front/ui-elements/FormDate'
import Text from 'antd/es/typography/Text'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Row, Col, Divider, message, Popconfirm, Drawer, Space, Tooltip } from 'antd'
import { QuestionCircleOutlined, CaretLeftOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons'
import { dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import { FC, useEffect, useState } from 'react'
import { useActionStatus } from '../../../../hooks/useActionStatus'
import { companyProfileBackGet } from '../../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from '../../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { setFormUpdated } from '../../../../store/kyc-front/slices/navSlice'
import BusinessProfileForm from './BusinessProfileForm'
import { ButtonUpper } from '../../../kyc-front/ui-elements/Buttons'
import { SuccessIcon } from '../../../kyc-front/ui-elements/SuccessInfo'
import { themeColor } from '../../../../styles/themeStyles'
import styled from 'styled-components'
import KycLayout, { NavItem } from '../../../kyc-front/kyc-layout/KycLayout'
import { businessProfilesGet } from 'store/kyc-backend/asyncThunks/businessProfilesGet'
import { businessProfileApproveClear, businessProfileCreateClear, businessProfilesGetClear } from 'store/kyc-backend/slices/businessProfileSlice'
import { businessProfileCreate } from 'store/kyc-backend/asyncThunks/businessProfileCreate'
import { businessProfileUpdate } from 'store/kyc-backend/asyncThunks/businessProfileUpdate'
import { BpGetPdfBtn } from './pdf/BpGetPdfBtn'
import { isV2CompanyProfileSnapshot, isV2IndividualProfileSnapshot, Profile } from './profile.guard'
import BusinessProfileHistoryTable from './BusinessProfileHistoryTable'
import { businessProfileApprove } from 'store/kyc-backend/asyncThunks/businessProfileApprove'

interface BProps {
    isArchived?: boolean,
    businessProfileId?: string
}

type ApprovalType = {
    approvedAt: string,
    approvedBy: string
}

export type ApprovalsType = {
    clientAdvisorApproval?: ApprovalType,
    complianceOfficerApproval?: ApprovalType
}

type CreationType = {
    status?: 'APPROVED' | 'DRAFT',
    createdAt: string,
    dataVersion: string,
    approvals?: ApprovalsType
}


const CenteredCol = styled(Col)`
    text-align: center;
    margin-bottom: 0;
    margin-top: 40px;
`

const Summary = styled.p`
    span {
        font-size: 16px;
        display: inline-block;
        &.value {
            width: auto;
            display: inline-block;
            margin-left: 5px;
        }
    }
`

export const BusinessProfileLayout: FC<BProps> = ({isArchived, businessProfileId}) => {
    const navigate = useNavigate()
    const envs = useSelector((state) => state.general.envs)
    const { businessProfiles, newBusinessProfile } = useSelector((state) => state.admin2.businessProfiles)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [isApproving, setIsApproving] = useState(false)
    const [disableModal, setDisableModal] = useState(false)
    const [bPData, setBPData] = useState<Profile>({})
    const [visible, setVisible] = useState(false)
    const [createNew, setCreateNew] = useState(false)
    const [popupVisible, setPopupVisible] = useState(false)
    const [shouldReloadListing, setShouldReloadListing] = useState(true)
    const businessProfilesGettingStatus = useActionStatus(businessProfilesGet)
    const businessProfileDraftCreateStatus = useActionStatus(businessProfileCreate)
    const businessProfileApprovingStatus = useActionStatus(businessProfileApprove)
	const isBusiness = envs.accountType === 'BUSINESS'
    
    const [isBack, setBack] = useState(false)
    const [finalApprove, setFinalApprove] = useState(false)
    let disabled = bPData?.status === 'DRAFT' ? false : true
    const businessProfileDraftUpdateStatus = useActionStatus(businessProfileUpdate)
    const initialValues: Record<any, any> = isV2CompanyProfileSnapshot(bPData) || isV2IndividualProfileSnapshot(bPData) ? {
        basicInformationComments: bPData?.data?.basicInformationComments,
        addressComments: bPData?.data?.addressComments,
        sourceOfWealthComments: bPData?.data?.sourceOfWealthComments,
        purposeOfEntityComments: bPData?.data?.purposeOfEntityComments,
    } : {}
    if(isV2CompanyProfileSnapshot(bPData)){
        initialValues.authorizedPersonAndUBOComments = bPData?.data?.authorizedPersonAndUBOComments
    }
    
    const setBusinessProfileData = (profileData: any) => {
        setBPData(profileData)
    }
    const handleHistoryShow = (historicalData: any) => {
        setBusinessProfileData(historicalData)
    }
    const handleBack = () => {
        setDataLoaded(false)
    }

    const newProfileClicked = () => {
        setDisableModal(true)
        setCreateNew(true)
    }
    useEffect(() => {
        if(createNew) {
            dispatch(businessProfileCreate({params: {businessProfileId: ''}, data: {clientId: envs.profileId}}))
            setCreateNew(false)
        }
    }, [createNew])
	useEffect(() => {
		if(businessProfileDraftCreateStatus.isFullfilled && newBusinessProfile.businessProfileId !== '') {
			navigate(envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/business-profile/' + newBusinessProfile.businessProfileId)
			message.success('Profile was successfully created')
            setShouldReloadListing(true)
			setDataLoaded(false)
			dispatch(businessProfileCreateClear({}))
			dispatch(businessProfilesGet({params: {clientId: envs.profileId}}))
		}
	}, [newBusinessProfile])
    useEffect(() => {
        if(shouldReloadListing) {
            if(businessProfileDraftCreateStatus.isFullfilled && !businessProfileDraftCreateStatus.isCleared) {
                dispatch(businessProfilesGet({params: {clientId: envs.profileId}}))
                setShouldReloadListing(false)
            }
        }
    }, [businessProfileDraftCreateStatus])
	useEffect(() => {
        if(shouldReloadListing) {
            if(businessProfileDraftUpdateStatus.isFullfilled && !businessProfileDraftUpdateStatus.isCleared) {
                dispatch(businessProfilesGet({params: {clientId: envs.profileId}}))
                setShouldReloadListing(false)
                
            }
        }
    }, [businessProfileDraftUpdateStatus])
    useEffect(() => {
        if(businessProfileApprovingStatus.isError) {
            message.error('Some error occured while approving this form')
            dispatch(businessProfileApproveClear({}))
        }
        if(businessProfileApprovingStatus.isFullfilled) {
            dispatch(businessProfilesGet({params: {clientId: envs.profileId}}))
            dispatch(businessProfileApproveClear({}))
            setIsApproving(true)
        }
    }, [businessProfileApprovingStatus])
	useEffect(() => {
        if(isBack && dataLoaded === false) {
            callInitialFunctions()
            setBack(false)
        }
        
    }, [dataLoaded])
    useEffect(() => {
        dispatch(setFormUpdated({updated: false, form: []}))
        return(() => {
            dispatch(businessProfilesGetClear({}))
        })
    }, [])
    useEffect(() => {
        callInitialFunctions()
    }, [envs.accountType])
    useEffect(() => {
        setDisableModal(businessProfiles.find(el => el?.status === 'DRAFT') ? true : false)
        if(businessProfilesGettingStatus.isError) {
            message.error('Some error occured while getting this element')
        }
        if(businessProfilesGettingStatus.isFullfilled && businessProfiles.length) {
			let targetSnapshot = businessProfileId ? businessProfiles.find((elem: any) => elem.id === businessProfileId) : undefined
            if(targetSnapshot) {
                setBusinessProfileData(targetSnapshot)
            } else {
                if(!businessProfileId) {
                    const approvedProfile = businessProfiles.find((elem: any) => elem?.status === 'APPROVED')
                    setBusinessProfileData(approvedProfile ? approvedProfile : businessProfiles[0])
                }
            }
            setDataLoaded(true)
        }
    }, [businessProfilesGettingStatus])
    useEffect(() => {
        if(bPData.clientAdvisorApproval?.approvedBy && bPData.complianceOfficerApproval?.approvedBy && isApproving) {
            setFinalApprove(true)
            setIsApproving(false)
        }
    }, [bPData])
    const handleConfirmVisibleChange = () => {
        setPopupVisible(!popupVisible)
    }
    const cancelConfirm = () => {
        setPopupVisible(false)
    }
    const confirmNew = () => {
        setPopupVisible(false)
        newProfileClicked()
    }
    const showDrawer = () => {
        setVisible(true)
    }
	const onClose = () => {
	    setVisible(false)
	}
    const callInitialFunctions = () => {
        if(dataLoaded === false && envs.accountType !== '') {
            if(isBusiness) {
                dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }))
            }
            if(envs.accountType === 'PRIVATE') {
                dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}))
            }
        }
    }
    const hideFinalApprove = () => {
        setFinalApprove(false)
        navigate(envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/business-profile/' + bPData.id)

    }
    return (
        <KycLayout nav={{hideNav: true, hideSteps: true, navSubpage: 'business-profile', navPage: NavItem.CLIENT_PROFILES}}>
			<Row gutter={30}>
				<Col span={24}>
					<Row justify='space-between'style={{'position': 'relative', 'top' : '-16px'}}>
						{businessProfiles.length ? (
							<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Business Profile status: 
								<Text style={{color: bPData?.status === 'APPROVED' ? themeColor.green : bPData?.status === 'DRAFT' ? themeColor.blue : themeColor.blueGray}}><b> {bPData?.status ? bPData?.status : 'No status'}</b></Text>
							</Text>
						) : (
							<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>No business profile</Text>
						)}
						<div>
							<span style={{marginRight: 10}}><ButtonUpper disabled={businessProfiles.length < 1} btnType='default' onClick={showDrawer}>History</ButtonUpper></span>
							<Tooltip title={disableModal && 'Client already has a draft profile created'}><ButtonUpper btnType='primary' onClick={confirmNew} disabled={disableModal}>New Profile</ButtonUpper></Tooltip>
						</div>
					</Row>
					{finalApprove ? (
							<div>
								<Row justify='center'>
									<CenteredCol>
										<SuccessIcon />
										<p><b>Approved!</b></p>
										<p>Your have succesfully approved this Business Profile</p>
									</CenteredCol>
								</Row>
								<Row justify='center' style={{'marginTop': '20px', 'marginBottom': '40px'}}>
									<CenteredCol>
										<ButtonUpper onClick={hideFinalApprove} btnType='default'>View Business Profile</ButtonUpper>
									</CenteredCol>
								</Row>
							</div>
						) : (
							<>
								{businessProfiles.length ? (
								<Row>
									<Col span={24} style={{marginTop: -20, marginBottom: 20}}>
										<Divider />
										<Title level={4} style={{'marginBottom': '20px', 'marginTop': '30px'}}>Profile Summary</Title>
										<Summary>
											<span>Created at:</span>
											<span className='value'><b>{bPData.createdAt === '' || bPData.createdAt === undefined ? 'No data' : moment(bPData.createdAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
										</Summary>
										{bPData?.status === 'APPROVED' ? (
											<Summary>
												<span>Approved at:</span>
												<span className='value'><b>{moment(bPData.clientAdvisorApproval?.approvedAt).isAfter(bPData.complianceOfficerApproval?.approvedAt) ? moment(bPData.clientAdvisorApproval?.approvedAt).format('YYYY-MM-DD HH:mm:ss') : moment(bPData.complianceOfficerApproval?.approvedAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
											</Summary>
											) : (<></>)
										}
										<Summary>
											<span>Version:</span>
											<span className='value'><b>{bPData.formVersion ? bPData.formVersion : 'Not created'}</b></span>
										</Summary>
									</Col>
								</Row>) 
								: (<></>)}
								<Drawer title="Business Profile History" size='large' placement="right" onClose={onClose} open={visible}>
									<BusinessProfileHistoryTable handleHistoryShow={handleHistoryShow} />
								</Drawer>
								{businessProfiles.length && businessProfileId ? (
									<BusinessProfileForm 
										printRender={false}
										initialValues={initialValues} 
										disabled={disabled} 
										bPData={bPData}
										snapshotId={businessProfileId}
									>
										<div style={{marginBottom: 30, display: bPData?.status === 'APPROVED' ? 'block' : 'none'}}> 
											<Divider />
											<Title level={3}>Download Business Profile</Title>
                                            <BpGetPdfBtn data={bPData}/>
										</div>
									</BusinessProfileForm>
								) : (
									<></>
								)}
							</>
						)}
				</Col>
			</Row>
        </KycLayout>
    )
}

export default BusinessProfileLayout

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { api2 } from "api/kyc/api";
import { Paths } from "api/kyc/generated/client2";

type PathParameters = Paths.ApproveRiskProfile.PathParameters

type Response = Paths.ApproveRiskProfile.Responses.$204


export const riskProfileApprove: AsyncThunkActionType<
    Response,
    AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk(
  "riskProfileApprove",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.approveRiskProfile(params, data, config);
      return response.data as Response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

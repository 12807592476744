import { createSlice } from "@reduxjs/toolkit";
import { Paths, Components } from "api/kyc/generated/client2"
import { CaseReducerType } from "../../store";
import { riskProfilesGet } from "../asyncThunks/riskProfilesGet";
import { riskProfileCreate } from "../asyncThunks/riskProfileCreate";
import { riskProfileApprove } from "../asyncThunks/riskProfileApprove";
import { riskProfileDelete } from "../asyncThunks/riskProfileDelete";
import { riskProfileUpdate } from "../asyncThunks/riskProfileUpdate";

export type RiskProfileType = Components.Schemas.RiskProfile
export interface RiskCriterium {
    name: string,
    description: string,
    points: string
}

export interface EvaluationType {
    source?: ManualEvaluation | NamedSource;
    criterionMet?: boolean;
    message?: string;
    priority?: "NORMAL" | "HIGH";
    createdAt: string;
}
export interface EvaluationResultType {
    criterion?: string;
    points?: number; // int32
    description?: string;
    criteriaMet?: boolean;
    evaluations?: EvaluationType[];
}

export type ManualEvaluation = Components.Schemas.ManualEvaluation
export type NamedSource = Components.Schemas.NamedSource

type State = {
  riskProfiles: RiskProfileType[],
  riskProfileCreated: RiskProfileType,
  riskProfileUpdated: RiskProfileType
  riskProfileApproved: Paths.ApproveRiskProfile.Responses.$204,
  riskProfileDeleted: Paths.DeleteRiskProfile.Responses.$204
}

const initialState: State = {
    riskProfiles: [],
    riskProfileCreated: {},
    riskProfileUpdated: {},
    riskProfileApproved: false,
    riskProfileDeleted: false
};

type CaseReducers<State> = {
    riskProfilesGetClear: CaseReducerType<State, object>;
    riskProfileCreateClear: CaseReducerType<State, object>;
    riskProfileUpdateClear: CaseReducerType<State, object>;
    riskProfileApproveClear: CaseReducerType<State, object>;
    riskProfileDeleteClear: CaseReducerType<State, object>;
};  

export const riskSlice = createSlice<State, CaseReducers<State>>({
  name: "risk",
  initialState,
  reducers: {
    riskProfilesGetClear: (state, { payload }) => {
        state.riskProfiles = initialState.riskProfiles
    },
    riskProfileCreateClear: (state, { payload }) => {
        state.riskProfileCreated = initialState.riskProfileCreated
    },
    riskProfileUpdateClear: (state, { payload }) => {
        state.riskProfileUpdated = initialState.riskProfileUpdated
    },
    riskProfileApproveClear: (state, { payload }) => {
        state.riskProfileApproved = initialState.riskProfileApproved
    },
    riskProfileDeleteClear: (state, { payload }) => {
        state.riskProfileDeleted = initialState.riskProfileDeleted
    },
  },
  extraReducers: (builder) => {
    builder.addCase(riskProfilesGet.fulfilled, (state, action) => {
        state.riskProfiles = action.payload;
    });
    builder.addCase(riskProfileCreate.fulfilled, (state, action) => {
        state.riskProfileCreated = action.payload;
    });
    builder.addCase(riskProfileUpdate.fulfilled, (state, action) => {
        state.riskProfileUpdated = action.payload;
    });
    builder.addCase(riskProfileApprove.fulfilled, (state, action) => {
        state.riskProfileApproved = action.payload;
    });
    builder.addCase(riskProfileDelete.fulfilled, (state, action) => {
        state.riskProfileDeleted = action.payload;
    });
  },
});

export const { 
    riskProfilesGetClear,
    riskProfileCreateClear,
    riskProfileUpdateClear,
    riskProfileDeleteClear,
    riskProfileApproveClear
} = riskSlice.actions;
export default riskSlice.reducer;

import Title from 'antd/es/typography/Title'
import { ExclamationCircleOutlined, QuestionCircleOutlined, CommentOutlined } from '@ant-design/icons'
import { moment } from '../../../kyc-front/ui-elements/FormDate'
import { Row, Col, Table, Tooltip, Form } from 'antd'
import { useSelector } from '../../../../hooks/useSelector'
import React, { FC, useEffect, useState } from 'react'
import ApprovalSignature from '../../ui-elements/ApprovalSignature'
import { ButtonUpper } from '../../../kyc-front/ui-elements/Buttons'
import { FormInputStandalone } from '../../../kyc-front/ui-elements/FormInput'
import { FormCheckboxRadioFormStandalone } from '../../../kyc-front/ui-elements/FormCheckboxRadio'
import { camelCaseToName } from '../../../../utilities/nameParsers'
import { themeColor } from '../../../../styles/themeStyles'
import styled from 'styled-components'
import { EvaluationResultType, EvaluationType, ManualEvaluation, NamedSource } from 'store/kyc-backend/slices/riskSlice'
import { useActionStatus } from 'hooks/useActionStatus'
import { riskProfilesGet } from 'store/kyc-backend/asyncThunks/riskProfilesGet'
import { riskProfileApprove } from 'store/kyc-backend/asyncThunks/riskProfileApprove'

const TableCustom = styled(Table)`
	.criteriumMet {
		background-color: ${themeColor.grayOpaque}
	}
`

interface RProps {
    initialData: any,
    isEditable: boolean,
    handleSave: any,
    submittingComment: any,
    printRender: boolean
}


const RiskProfileForm: FC<RProps> = ({initialData, isEditable, handleSave, submittingComment, printRender, children}) => {
	const adminProfile = useSelector((state) => state.admin.admin);
	const profileGetState = useActionStatus(riskProfilesGet)
	const [message, setMessage] = useState('')
	const [isMet, setIsMet] = useState('false')

	const handleInputChange = (val: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(val.target.value)
	}
	const handleRadioChange = (val: React.ChangeEvent<HTMLInputElement>) => {
		setIsMet(val.target.value)
	}

    const columns = [
	    {
	        title: 'Risk criteria',
	        dataIndex: 'criterion',
	        key: 'criterion',
	        render: (criterion: any) => <><span>{printRender ? criterion.description : criterion.name === 'ContributorIsPEP' ? 'Contributor is PEP' : criterion.name === 'SpecificLicence2Operate' ? 'Has Specific License to Operate' : criterion.name === 'NoPersonalContact2Client' ? 'There Was No Personal Contact With Client' : camelCaseToName(criterion.name)}</span> <Tooltip title={criterion.description}><QuestionCircleOutlined style={{color: themeColor.gray}} /></Tooltip></>,
	    },
	    {
	        title: 'Result',
	        dataIndex: 'criteriaMet',
	        key: 'criteriaMet',
			render: (criteriaMet: any) => <span style={{textTransform: 'uppercase', fontWeight: 500, color: criteriaMet ? themeColor.orangeWarning : themeColor.green}}>{criteriaMet ? 'Met' : 'Not met'}</span>,
	    },
	    {
	        title: 'Client score',
	        dataIndex: 'score',
	        key: 'score',
	       	width: printRender ? 100 : 'auto',
	        render: (score: any) => <span> <b style={{color: score.score > 0 ? themeColor.orangeWarning : themeColor.green }}>{score.score}</b>/{score.points}</span>,
	    },
	    {
	        title: 'Evaluations',
	        dataIndex: 'evaluations',
	        key: 'evaluations',
	        className: 'commentsColumn',
	        width: printRender ? 0 : 'auto',
	        render: (evaluations: any) => <span>{evaluations?.length ? <span>{evaluations.length} <Tooltip title={'Newest evaluation: ' + evaluations[0].message + ' - Result: ' + (evaluations[0].criterionMet ? 'MET' : 'NOT MET')}><CommentOutlined style={{color: themeColor.blue}} /></Tooltip></span>:<></>}</span>,
	    },
	    Table.EXPAND_COLUMN
    ];

	const columns2 = [
	    {
	        title: 'Result',
	        dataIndex: 'criterionMet',
	        key: 'criterionMet',
	        render: (criterionMet: boolean) => <>Result: <span style={{color: criterionMet === true ? themeColor.orangeWarning : themeColor.green, fontWeight: 500, textTransform: 'uppercase'}}>{criterionMet ? 'Met' : 'Not met'}</span></>,
	    },
	    {
	        title: 'Message',
	        dataIndex: 'message',
	        key: 'message',
	       	width: printRender ? 100 : 'auto'
	    },{
	        title: 'Created at',
	        dataIndex: 'createdAt',
	        key: 'createdAt',
	        width: printRender ? 0 : 'auto',
			render: (createdDate: boolean) => <> {moment(createdDate).format('YYYY-MM-DD HH:mm:ss')}</>
	    },{
			title: 'Source',
	        dataIndex: 'source',
	        key: 'source',
	        width: printRender ? 0 : 'auto',
			render: (source: any) => <>{source?.type === 'named_source' ? `Source: ${source.name}` : <>{camelCaseToName(adminProfile.profiles.find((elem) => elem.adminId === source.adminId)?.username)}</>}</>
	    },{
			title: 'Priority',
	        dataIndex: 'priority',
	        key: 'priority',
	        width: printRender ? 0 : 'auto',
			render: (priority: any) => <span>{priority} priority</span>
		}
    ];
	return (
		<>
			<Form className='ant-form-vertical' id='riskProfileFormInnner'>
				{initialData.evaluationResult &&
					<TableCustom
						style={{marginBottom: 30}}
                		columns={columns}
                		pagination={false}
                		rowClassName={(record, index) => {
			                if (record.criteriaMet) {
			                  return "criteriumMet";
			                } else {
			                    return ""
			                }
			            }}
                		expandable={{
			                expandedRowRender: (criterium: EvaluationResultType, i) => {
			                	return (
			                    <Row justify="space-between" style={{padding: '10px 0'}} key={'criterium' + i}>
			                        <Col span={24}>
			                        	{/*<FormInput showApprove={false} label='Points' name={'evaluationResult[' + i + '].criterion.points'}  error={errors?.evaluationResult ? errors.evaluationResult[i]?.criterion.points : ''} onChange={handleInputChange} disabled={false} printRender={false} value={values.evaluationResult[i].criterion.points} type='company' />*/}
				                            <Row gutter={30}>
												<Col span={24}>
													{criterium.evaluations?.length ? 
													<>
														<p>Evaluations:</p>
														<Table
															dataSource={criterium.evaluations}
															columns={columns2}
															loading={!criterium.evaluations?.length && !profileGetState.isPending}
															pagination={false}
															showHeader={false}
														/>
													</>
													: <p>No evaluations</p>}
												</Col>
											</Row>
											{isEditable &&
												<Row>
													<Col span={24}>
														<br />
														<label style={{marginBottom: 10, display: 'block'}}>Risk criterium met:</label>
														<FormCheckboxRadioFormStandalone label='Purpose of business relationship' id={"isMet" + i} type='radio' value={isMet} onChange={(e) => handleRadioChange(e)}
															options={[{
																	label: 'Met',
																	value: 'true'
																},
																{
																	label: 'Not met',
																	value: 'false'
																}
															]
														} disabled={false} />
													</Col>
												</Row>
											}
							            <br />
							            <Row gutter={30}>
											<Col span={24}>
				                            	{isEditable &&<FormInputStandalone label='New evaluation:' name='evaluationResult' onChange={(e) => handleInputChange(e)} disabled={false} value={submittingComment[i]} />}
			                        		</Col>
			                        	</Row>
			                        	<br />
			                        	<Row gutter={30}>
						                	<Col span={24}>
								            	{isEditable && <ButtonUpper className='btn-md' btnType='primary' onClick={() => handleSave(initialData.id, message, isMet, criterium.criterion)}>Save manual evaluation</ButtonUpper>}
			                        		</Col>
			                        	</Row>
			                        </Col>
			                    </Row>
			                )},
			                expandIcon: ({ expanded, onExpand, record }) => {
			                    const handleExpand = (record: object, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			                        onExpand(record, e);
			                    }
			                    return (
			                        <>
			                        {expanded ? (
			                            <ButtonUpper className='btn-sm' btnType='default' onClick={e => handleExpand(record, e)}>Close</ButtonUpper>
			                        ) : (
			                            <ButtonUpper className='btn-sm' btnType='default' onClick={e => handleExpand(record, e)}>Details</ButtonUpper>
			                        )}
			                        </>
			                    )
			                }
			            }}
                		dataSource={initialData.evaluationResult?.map((criterium: any, i: number) => {
                			let el = {
                    			key: i, 
                    			criterion: {description: criterium.description, name: criterium.criterion ? criterium.criterion : criterium.name}, 
                    			criteriaMet: criterium.criteriaMet,
                    			evaluations: criterium.evaluations
								? [...criterium.evaluations].sort((a, b) => 
									new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
								  )
								: [],
                    			score: {score: criterium.criteriaMet ?  criterium.points : 0, points: criterium.points}
                			}
                    		return el;
                		})}
            		/>
            	}
        	</Form>
        	<div style={{marginBottom: printRender ? 20 : 50, marginTop: printRender ? 10 : 50}}>
            	<Title level={3}>Risk Profile Approvals</Title>
            </div>
      		<Row>
				{[...Array(initialData.requiredApprovals)].map((val, i) => {
					let thisApproval = initialData?.approvals?.length ? initialData?.approvals[i] : undefined
					let approvedBy = thisApproval?.approvedBy
					let approvedAt = thisApproval?.approvedAt
					return (
					<Col key={i}>
						<ApprovalSignature 
							approveUpdate={riskProfileApprove}
							approveParams={{id: initialData.id}}
							isEditable={isEditable}
							title={"Approval " + (i + 1)} 
							type="RiskProfileApproval" 
							noData={true}
							value={approvedBy}
							time={approvedAt}
							snapshotId={initialData.id} 
							disabled={(i > (initialData?.approvals?.length)) || initialData?.approvals?.find((el: any) => el.approvedBy === adminProfile.profile.adminId)}
						/>
					</Col>
				)})}
      			
		    </Row>
		    {children}
		</>		    
			 
	)
}

export default RiskProfileForm

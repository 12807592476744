import { createAsyncThunk } from "@reduxjs/toolkit"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { api2 } from "api/kyc/api";
import { Components, Paths } from "api/kyc/generated/client2"
import { ApprovedDocResponse } from "../slices/signedDocsSlice"

type reqBody = {
  data: Paths.ApproveSignedKyc.RequestBody,
  name: string
}
type PathParameters = Paths.ApproveSignedKyc.PathParameters
type approveResp = {
  name: string,
  data: ApprovedDocResponse
}

export const signedDocApprove: AsyncThunkActionType<
  approveResp,
  AsyncThunkDataType<PathParameters, reqBody, "params">
> = createAsyncThunk(
  "signedDocApprove",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.approveSignedKyc(params, data?.data, config);
      return {name: data?.name, data: response.data} as approveResp;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

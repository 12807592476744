import { FC } from "react"
import Text from 'antd/es/typography/Text'
import ActionModalComponent from "modules/kyc-front/user-actions/ActionModalComponent"

type BProps = {
	open: boolean,
	handleOk: (value: any) => void,
	handleCancel: (value: any) => void
}

const BlockClientModal: FC<BProps> = ({open, handleOk, handleCancel}) => (
	<ActionModalComponent
		open={open}
		handleOk={handleOk}
        name='Do you want to block this client?'
		okText='Block'
		handleCancel={handleCancel}
		cancelText='Cancel'
	>
        <br />
        <Text>For a blocked client system appears normal.</Text><br />
        <Text>All transactions initiated by the blocked client<br /> remain perpetually "In Progress".<br /><br /> None of them will be executed</Text>
  </ActionModalComponent>
)

export default BlockClientModal
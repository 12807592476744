import auth, { AuthState } from 'modules/keycloak/store/authSlice'
import envs from "./kyc-front/slices/envsSlice";

import individual from "./kyc-front/slices/individualSlice";
import profile from "./kyc-front/slices/profileSlice";
import device from "./kyc-front/slices/deviceSlice";
import nav from "./kyc-front/slices/navSlice";
import errorsState from "./kyc-front/slices/errorsSlice";
import responseInfo from './kyc-front/slices/responseInfo'
import forbidden from './kyc-front/slices/forbiddenSlice'

import backCompany from "./kyc-backend/slices/companySlice";
import backIndividual from "./kyc-backend/slices/individualSlice";
import admin from "./kyc-backend/slices/adminSlice";
import backNotes from "./kyc-backend/slices/notesSlice";
import backIdentity from "./kyc-backend/slices/identitySlice";

//GENERAL V2
import user from "./kyc-front/slices/userSlice"
import identity from "./kyc-front/slices/identitySlice";

//API V2 CLIENT
import kycApplication from "./kyc-front/slices/kycApplicationSlice"
import kycApplicationChanges from "./kyc-front/slices/kycApplicationChangesSlice"
import docs from './kyc-front/slices/docsSlice'
import messages from "./kyc-front/slices/messagesSlice"

//API V2 ADMIN
import account from './kyc-backend/slices/accountSlice'
import adminInbox from "./kyc-backend/slices/adminInboxSlice"
import applicationApprovals from "./kyc-backend/slices/backKycApplication"
import signedDocs from './kyc-backend/slices/signedDocsSlice'
import products from './kyc-backend/slices/productsSlice'
import approvalErrors from './kyc-backend/slices/kycApprovalErrors'
import clients from './kyc-backend/slices/clientsSlice'
import businessProfiles from "./kyc-backend/slices/businessProfileSlice"
import riskProfiles from "./kyc-backend/slices/riskSlice"

import { persistReducer, persistCombineReducers } from 'redux-persist'
import { combineReducers } from 'redux';
import storage from "redux-persist/lib/storage"


const authWhiteList: (keyof AuthState)[] = ['session', 'backToUri']
const authConfig = {
	key: 'auth',
	storage,
	whitelist: authWhiteList as string[],
}


const generalReducer = combineReducers({
  auth: persistReducer(authConfig, auth),
  envs,
  device,
  responseInfo,
  nav,
  forbidden,
  errorsState
})

const generalReducer2 = combineReducers({
  identity,
  user
})

const adminReducer = combineReducers({
  backCompany,
  backIndividual,
  admin,
  backNotes,
  backIdentity
});

const userReducer = combineReducers({
  profile,
  individual
});

const user2Reducer = combineReducers({
  kycApplication,
  kycApplicationChanges,
  messages,
  docs
});

const admin2Reducer = combineReducers({
  account,
  adminInbox,
  applicationApprovals,
  approvalErrors,
  businessProfiles,
  clients,
  products,
  signedDocs,
  riskProfiles
})

const slices = {
  general: generalReducer,
  general2: generalReducer2,
  user: userReducer,
  user2: user2Reducer,
  admin: adminReducer,
  admin2: admin2Reducer
}

export default slices;

import { Button, Popconfirm, ButtonProps, theme } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { themeColor } from '../../../styles/themeStyles'

type BProps = ButtonProps & {
	// @deprecated use build in prop 'type' instead
	btnType?: ButtonProps['type']
}

const PopconfirmCustom = styled(Popconfirm)`
    .ant-popover {
        min-width: 350px;
        text-transform: none;
    }
`

const BtnUpper = styled(Button)`
	text-transform: uppercase;
	&.ant-btn:not(.ant-btn-primary):not([DISABLED]) {
		&:active, &:hover, &:focus  {
			color: ${themeColor.primaryColor};
			border-color: ${themeColor.primaryColor};
		}
	}
	&.left {
		margin-right: 30px;
	}
	&.dashed-primary:not([DISABLED]) {
		border-color: ${themeColor.primaryColor}; 
		color: ${themeColor.primaryColor}
	}
	&.ant-btn-primary {
		background-color: ${themeColor.primaryColor};
		border-color: ${themeColor.primaryColor};
		&.ant-btn-background-ghost {
			background-color: #fff;
			color: ${themeColor.primaryColor};
		}
	}
	&[DISABLED].ant-btn-primary:not(.ant-btn-background-ghost) {
		background-color: ${themeColor.primaryLight};
		border-color: ${themeColor.primaryLight};
		color: white
	}
	&[DISABLED]:not(.ant-btn-primary),
	&[DISABLED].ant-btn-background-ghost {
		background-color: transparent!important;
		color: ${themeColor.grayLight};
	}
	&.btn-sm {
		padding: 1px 10px;
		height: auto;
	}
	&.ant-btn-text {
		color: ${themeColor.primaryColor};
		&[DISABLED] {
			color: ${themeColor.grayLight};
		}
	}
	&.btn-info {
		background-color: ${themeColor.grayOpaque};
		border-color: ${themeColor.grayOpaque};
	}
	&.btn-warning {
		background-color: ${themeColor.orangeLight};
		border-color: ${themeColor.orangeWarning};
		color: ${themeColor.orangeWarning};
		&.ant-btn {
			&:not(.ant-btn-primary) {
				&:focus, &:hover, &:active {
					background-color: ${themeColor.orangeDarker};
					border-color: ${themeColor.orangeWarning};
					color: ${themeColor.orangeWarning};
				}
			}
		}
		
	}
	&.btn-success {
		background-color: #fff;
		border-color: ${themeColor.green};
		color: ${themeColor.green};
	}
	&.btn-dashed {
		color: ${themeColor.primaryColor};
		border-color: ${themeColor.primaryColor};
	}
	&.block, &.ant-btn-block {
		display: block;
	}
	
`



export const ButtonUpper: FC<BProps> = ({btnType, className, ...props}) => {
	return (
		<BtnUpper type={btnType} size={'large'} className={className ? className : ''} {...props} />
	)
}

interface BWProps {
	popupVisible: boolean,
	confirm: (value: any) => void,
	cancel: (value: any) => void,
	handleVisibleChange: (value: any) => void,
	children: ReactNode
}

export const ButtonWrapper: FC<BWProps> = ({
	popupVisible, confirm, cancel, handleVisibleChange, children
}) => {
	return (
		<PopconfirmCustom
          	title="You made some changes to this form. Do You want to continue without saving Your form?"
          	visible={popupVisible}
          	onVisibleChange={handleVisibleChange}
          	onConfirm={confirm}
          	onCancel={cancel}
          	okText="Continue"
          	cancelText="Cancel"
          	icon={<QuestionCircleOutlined />}
        >
			{children}
		</PopconfirmCustom>
	)
}
